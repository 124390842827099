import React  from "react";
import { Link } from "react-router-dom";
import css from '../../main.css'
import saNFTimg from '../../static/saNFT.png'
import saorusNFT from '../../static/orusNFT.png'
import logoHead from '../../static/logo.svg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'


// create a component

function MarketPlaceHome() {
    return (
        <div className="marketplace-home">
            <div className="mrk-nav">
                <p><a href="https://smartartistsnfts.github.io/"><span style={{"color":"#A600FF"}}>SmartArtists</span></a><span className="txt-logo"> is an awesome Marketplace for non-standard NFTs. Read the <a href="https://www.canva.com/design/DAEwY4NM08I/7qM9l7DxXhn6DqJugaj1yg/view?utm_content=DAEwY4NM08I&utm_campaign=designshare&utm_medium=link&utm_source=publishsharelink">Litepaper</a></span></p>
            </div>
            <div className="mrk-header">
                <div className="mrk-header-left">
                    <a target="_blank" href="https://smartartistsnfts.github.io/what-are-sanfts/"><img src={logoHead}/></a>
                </div>

                <div className="mrk-header-explorer">
                    <div className="expl-head">
                        <span>Explore</span>
                        </div>
                    <ul>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/"><FontAwesomeIcon icon="flask" /> How It Works</a></li>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/are-you-an-artist/"><FontAwesomeIcon icon="plus-square" /> Create Your saNFT</a></li>
                        <li className="expl-it"><a target="_blank" href="https://smartartistsnfts.github.io/tradingnomics/"><FontAwesomeIcon icon="hand-holding-usd" /> How to Earn</a></li>
                        <li className="expl-it"><a target="_blank" href="https://discord.gg/3T2VrRYN"><FontAwesomeIcon icon="question-circle" /> Got any Questions?</a></li>
                    </ul>
                </div>

            </div>
            
            <div className="mrk-body">
                <div className="mrk-body-top"></div>
                <div className="mrk-body-showcase">
                    <div className="sa-nft">
                        
                        <div className="sa-nft-body">
                            <img src={saNFTimg}></img>
                            <div className="sa-nft-header">
                                <h2 className="name-info">SmartArtists NFT</h2>
                                <h4 className="name-info"><a href="/">tdep</a></h4>

                            </div>

                            <div className="sa-nft-info">
                                <p>Artist fee: 3%</p>
                                <p>Pool fee: 7%</p>
                            </div>
                            <form>
                                <label>
                                    <input type="text" placeholder="Your Buy Offer" id="buy_offer"/>
                                </label>
                            </form>
                            <Link to='/'>Get It With Albedo</Link>
                        </div>
                    </div>
                    
                    
                </div>
            </div>
        </div>
    )
}

export default MarketPlaceHome;
