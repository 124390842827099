import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  Demo,
  MarketPlaceHome,
  GetSmarTdep,
  Manifest,
  Redir
} from "./components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Icons from "@fortawesome/fontawesome-free-solid"


ReactDOM.render(
  <Router>
    <Routes>
      <Route path="/" element={<Redir/>}/>
      <Route path="/4d56d124ff5999eb5b7708ff6ad5c610" element={<MarketPlaceHome />} />
      <Route path="/demo" element={<Demo />} />
      <Route path="/manifest" element={<Manifest />} />
      <Route path="/getSmarTdep" element={<GetSmarTdep/>}/> 
    </Routes>

  </Router>,

  document.getElementById("root")
);

