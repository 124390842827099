import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

import axios from 'axios'
import { 
    Server,
    Networks,
    Transaction,
    Asset
  } from "stellar-sdk"

import albedo from '@albedo-link/intent'

const HORIZON_URL = "https://horizon-testnet.stellar.org/"
const STELLAR_NETWORK = "TESTNET"
  
const server = new Server(HORIZON_URL)


function signXDR(resp, resp1) {
    const transaction = new Transaction(resp.xdr, Networks.TESTNET)
    transaction.addSignature(resp.signer, resp.signature)
    transaction.addSignature(resp1.signer, resp1.signature)
  
    return transaction.toXDR()
  }


  async function completeTransaction(userPK) {

    const buy_offer = window.buy_amm
    console.log(buy_offer)
  
    const data = JSON.stringify({
      "userPK": `${userPK.toString()}`,
      "fee": 100,
      "time_bounds": await server.fetchTimebounds(100),
      "buy_offer": buy_offer
    });
    
    var config_tss = {
      method: 'post',
      url: 'https://stellar-turrets-testnet.sdf-ecosystem.workers.dev/tx-functions/2a9e160cae40cf0ef27e3e31b4b52eac3b03cbf59a2d30ec61e7d4c037b7973b',
      headers: { 
        'Authorization': 'Bearer AAAAAgAAAAA2IvOSclgI4rz5Btqpy81wESVg5zY/JDGd6h0NbzmnfAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAA+GW0ohRNuoIAPfDT9Au08mmaWTOKQETtZcpiJuNRzr9AAAAAQAAAAAAAAAKAAAADnR4RnVuY3Rpb25IYXNoAAAAAAABAAAAQDJhOWUxNjBjYWU0MGNmMGVmMjdlM2UzMWI0YjUyZWFjM2IwM2NiZjU5YTJkMzBlYzYxZTdkNGMwMzdiNzk3M2IAAAAAAAAAAW85p3wAAABApvIxttJss0u5KHpcFuk9ZPXnVwXUYDk878Xkb7SjZCejrLSqFTpOUnnH2G4vnBu+hDCxsuaGmJr2mhPUE+CdCw', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    var config_tss_1 = {
      method: 'post',
      url: 'https://tss-wrangler-testnet.tdep.workers.dev/tx-functions/2a9e160cae40cf0ef27e3e31b4b52eac3b03cbf59a2d30ec61e7d4c037b7973b',
      headers: { 
        'Authorization': 'Bearer AAAAAgAAAAA2IvOSclgI4rz5Btqpy81wESVg5zY/JDGd6h0NbzmnfAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAA+GW0ohRNuoIAPfDT9Au08mmaWTOKQETtZcpiJuNRzr9AAAAAQAAAAAAAAAKAAAADnR4RnVuY3Rpb25IYXNoAAAAAAABAAAAQDJhOWUxNjBjYWU0MGNmMGVmMjdlM2UzMWI0YjUyZWFjM2IwM2NiZjU5YTJkMzBlYzYxZTdkNGMwMzdiNzk3M2IAAAAAAAAAAW85p3wAAABApvIxttJss0u5KHpcFuk9ZPXnVwXUYDk878Xkb7SjZCejrLSqFTpOUnnH2G4vnBu+hDCxsuaGmJr2mhPUE+CdCw', 
        'Content-Type': 'application/json'
      },
      data : data
    };
  
    axios.all([
      axios(config_tss),
      axios(config_tss_1),
    ])
    .then(axios.spread((tss_out_data1, tss_out_data2) => {
      console.log(tss_out_data1, tss_out_data2);
      const xdr = signXDR(tss_out_data1.data, tss_out_data2.data)
      albedo.tx({
        xdr: xdr,
        network: 'testnet'
    })
        .then(res => {
          server.submitTransaction(new Transaction(res.signed_envelope_xdr, Networks.TESTNET))
          //modal = document.getElementById("myModal");  
          //modal.style.display = "block";
        })
    }));
  }

  function GetSmartTdep() {
    console.log("tdep")
    albedo.publicKey({
      token: ''
    })
      .then(res => {
        const userPK = res.pubkey
  
        if (userPK.length > 10) {
          console.log(userPK)
        }
        completeTransaction(userPK)
        
      })
  
  
    return (
      <p id="waitingAfterAsset">Waiting ...</p>
    )
  
  }

export default GetSmartTdep