import React from 'react';

class Redir extends React.Component {
 componentDidMount(){
     window.location.href = "https://smartartistsnfts.github.io";
  }

  render() {
    return (
      <div>
       <h2>Contact</h2>
      </div>
    )
  }
}

export default Redir;