//import {marked} from 'marked';
import Markdown from "marked-react";
import React from "react";
import explanationAudio from '../output.mp3'



function Manifest() {
    const manifest_md = `# **The SmartArtists Manifest**

|  |  |
| :- | :- |
| [Website](https://smart-artists.pages.dev) 
| [Find This Project on the Stellar Community Fund](https://communityfund.stellar.org/projects/smartartists) | 

### **Hi to everyone who loves Stellar!**

*I'm Tommaso, a young developer and student from Italy. You can find me on [Medium](https://tdep.medium.com/)*.

I've been eager to announce that **SmartArtists**, the project I've been working on for a while, [has been selected](https://communityfund.stellar.org/current) by the Stellar panel for proceed in the *Stellar Community Fund round 9*. And this is a prefect occasion to finally publicly announce SmartArtists

## **What is smartartists up to?**

I'm building a decentralized marketplace running on Stellar that allows users to buy Digital Assets with Smart Contracts running on [Stellar Turrets](https://turrets.stellar.org/). 
These Diglital Tokens (NFTs) are not like other NFTs thoug. Leveraging HORIZON and the Stellar Network, which allows up to 100 operations for a single transaction, when buying a SmartArtist NFT you will pay not only the last buyer of the NFT, but pay a % of the amount you bought the Asset with to all the previous owners.
As you may have thought, the % that each owner receives once a new one buys the NFT depends on how much that owner spent to buy that Asset.

This is why you can decide the price to buy the NFT, but the higher your offer is, the higher return you will see. I've also set up a simple pooling functionality that prevents the formation of a **pyramid scheme**. Also, when buying an asset, a fixed % goes straight into the Artist's wallet: **this is a way to be able to earn by supporting your favourite artists**.

All the details will be available in the Litepaper I'm going to publish soon (I'll let you know on the smartartists channel when), I'm going to publish as well a small trading guide were I'll discuss further the basics behind the profit possibilities with SmartArtists. You will find these on the [webpage I'm currently building](https://smart-artists.pages.dev).

## **What is the progress so far?**

I've already build the contract upon which all the uploaded NFTs will be built, set up [my own turret on the testnet](https://tss-wrangler-testnet.tdep.workers.dev/), and built a simple web demo of aquiring a test SmarArtists asset: you can try it out [here](https://smart-artists.pages.dev/demo), it integrates with albedo. [This video](https://vimeo.com/648158084) will show you how to use this demo.

I'm currently focusing on finishing the litepaper, along with building a marketplace and a dashboard for the artists. The first step from now will be to have a collection of "StellarLovers" NFTs, which will be the first assets to be deployed to production. Once we get there, there will be a dashboard for artists to sign up and start creating their SmartArtists Digital Assets.

You can find the project on the SCF page [here](https://communityfund.stellar.org/projects/smartartists). The requested funding is **$1500**, the reason why is well explained [here](https://communityfund.stellar.org/projects/smartartists).



`

      return (
          <div id="manifest">
        <div className="md-content"><Markdown>{manifest_md}</Markdown></div><br/><div className="md-content"><h2>Listen and Learn SmartArtists in 2 Minutes</h2><audio controls>
              <source src={explanationAudio}  type="audio/mp3"/>
            </audio> </div></div>
      )
}

export default Manifest;