import axios from 'axios'
import { 
    Server,
    Networks,
    Transaction,
    Asset
  } from "stellar-sdk"

import albedo from '@albedo-link/intent'

  
import {
    BrowserRouter as Router,
    Route,
    Link
  } from "react-router-dom";
  
let modal;
let close_mod_span;

async function buildOwnersList() {
    const issuerPK = "GAMOSRP46XEIBLTI3JBX73GY5O3NLVMWXYZ2SUWR36ISCBGSR3ALV5VS"
    const smartVal = new Asset("SmarTdep", issuerPK)
  
    const trades = await server.trades().forAssetPair(smartVal, Asset.native()).limit(93).order("desc").call()
  
    let owners = []
    let records = trades.records
    
    let totSupply = []
    for (let i=0; i < records.length; i++) {
      totSupply.push(parseFloat(records[i].counter_amount))
    }
    
    const sumTotalSupply = Math.round((totSupply.reduce((a, b) => a + b, 0)) * 1000000) / 1000000
  
    return sumTotalSupply
  }

const HORIZON_URL = "https://horizon-testnet.stellar.org/"
const STELLAR_NETWORK = "TESTNET"
  
const server = new Server(HORIZON_URL)
const txfuncthash = "55ca89aca3b1b3f6d5b5e08a7ba8d0e0ee5e2f1d3684445ae5330a613140cf09"
  

function signXDR(resp, resp1) {
    const transaction = new Transaction(resp.xdr, Networks.TESTNET)
    transaction.addSignature(resp.signer, resp.signature)
    transaction.addSignature(resp1.signer, resp1.signature)
  
    return transaction.toXDR()
  }
      


async function completeTransaction(userPK) {

    const buy_offer = parseInt(document.getElementById('buy_offer').value)
    console.log(buy_offer)
  
    const data = JSON.stringify({
      "userPK": `${userPK.toString()}`,
      "fee": 100,
      "time_bounds": await server.fetchTimebounds(100),
      "buy_offer": buy_offer
    });
    
    var config_tss = {
      method: 'post',
      url: 'https://stellar-turrets-testnet.sdf-ecosystem.workers.dev/tx-functions/1580cf62cbf6c6a74045e85ce86d547aac5b510110cefa7eaa53ac53c4c6b322',
      headers: { 
        'Authorization': 'Bearer AAAAAgAAAAA2IvOSclgI4rz5Btqpy81wESVg5zY/JDGd6h0NbzmnfAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAA/ImMr1TsxaeZ5w5JH0d1dY8nfHsRx2qUF9uDA3rXQPDAAAAAQAAAAAAAAAKAAAADnR4RnVuY3Rpb25IYXNoAAAAAAABAAAAQDE1ODBjZjYyY2JmNmM2YTc0MDQ1ZTg1Y2U4NmQ1NDdhYWM1YjUxMDExMGNlZmE3ZWFhNTNhYzUzYzRjNmIzMjIAAAAAAAAAAW85p3wAAABABx2+jWOxlOTC4SGAVQj0GXMDxZlnMPcmynX9vuRAttgUGGguAUMCnpq+UNsCV6hMMiVhWW7NXkOVCan0cyWtBw==', 
        'Content-Type': 'application/json'
      },
      data : data
    };
    
    var config_tss_1 = {
      method: 'post',
      url: 'https://tss-wrangler-testnet.tdep.workers.dev/tx-functions/1580cf62cbf6c6a74045e85ce86d547aac5b510110cefa7eaa53ac53c4c6b322',
      headers: { 
        'Authorization': 'Bearer AAAAAgAAAAA2IvOSclgI4rz5Btqpy81wESVg5zY/JDGd6h0NbzmnfAAAAAAAAAAAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAAAAAA/ImMr1TsxaeZ5w5JH0d1dY8nfHsRx2qUF9uDA3rXQPDAAAAAQAAAAAAAAAKAAAADnR4RnVuY3Rpb25IYXNoAAAAAAABAAAAQDE1ODBjZjYyY2JmNmM2YTc0MDQ1ZTg1Y2U4NmQ1NDdhYWM1YjUxMDExMGNlZmE3ZWFhNTNhYzUzYzRjNmIzMjIAAAAAAAAAAW85p3wAAABABx2+jWOxlOTC4SGAVQj0GXMDxZlnMPcmynX9vuRAttgUGGguAUMCnpq+UNsCV6hMMiVhWW7NXkOVCan0cyWtBw==', 
        'Content-Type': 'application/json'
      },
      data : data
    };
  
    axios.all([
      axios(config_tss),
      axios(config_tss_1),
    ])
    .then(axios.spread((tss_out_data1, tss_out_data2) => {
      console.log(tss_out_data1, tss_out_data2);
      const xdr = signXDR(tss_out_data1.data, tss_out_data2.data)
      albedo.tx({
        xdr: xdr,
        network: 'testnet'
    })
        .then(res => {
          server.submitTransaction(new Transaction(res.signed_envelope_xdr, Networks.TESTNET))
          modal = document.getElementById("myModal");  
          modal.style.display = "block";
        })
    }));
  }
  
  
  function GetSmartVal() {
    console.log("smartval")
    albedo.publicKey({
      token: ''
    })
      .then(res => {
        const userPK = res.pubkey
  
        if (userPK.length > 10) {
          console.log(userPK)
        }
        completeTransaction(userPK)
        
      })
  
  
    return (
      <p id="waitingAfterAsset">Waiting ...</p>
    )
  
  }

function Demo() {
    modal = document.getElementById("myModal");  
    close_mod_span = document.getElementsByClassName("close")[0];
    setTimeout(function(){ 
        const source = document.getElementById('buy_offer'); 
        const result = document.getElementById('networkPortion');
 

        const inputHandler = async function(e) {
            let amount = parseInt(e.target.value)
            window.buy_amm = amount
            let owners = await buildOwnersList()
            console.log(owners)
            let perc = Math.round(((100*amount)/(owners+amount)) * 100) / 100
            document.getElementById("networkPortion").innerHTML = "You are " + perc + "% of the network"
            document.getElementById("totalLumens").innerHTML = "Total Lumens: " + owners
      }
    
        source.addEventListener('input', inputHandler); 
    }, 3000);
    return (
        
        <div id="testnetDemo">
            <div id="myModal" className="modal">
          <div className="modal-content">
            <span className="close">&times;</span>
          </div>
        </div>
          <h1>Try It Live on The Testnet</h1>
          
          <div id="demoContent">
            <div></div>
            <div className="buyAssetCard">
              <br/>
              <div className="buyAssetCard_content_head">
                <h2>SmarTdep</h2>
              </div>
                <br/>
                <div className="buyAssetCard_content_body">
                  <p>This is the first official SmartArtist Asset.</p>
                </div>
              <form>
                <label>
                  <input type="text" placeholder="Your Buy Offer" id="buy_offer"/>
                </label>

                <p id="totalLumens"></p>
                <p id="networkPortion"></p>
                <Link id="firstAssetLink" to={"/getSmarTdep"}>GET IT <span style={{fontSize: "1rem"}}>with Albedo</span></Link>
              </form>

            </div>
            
            <div id="demoInfo">
              <h1>You Are Buying a SmartArtist Asset</h1>

              <p>Once you buy this asset, you'll become a portion of the network (the % that appears after setting your buy amount). When a new buyer buys this asset, you'll receive a percentage of that buyer's Buy Amount.</p>
              <p className="demoS2">And that percentage is exactly your network portion percentage, so, the higher your buy amount, the higher your portion percentage, the higher the passive income.</p>

              
              
            </div>
          </div>
          </div>
    );
}

function TestAlbedo() {
    return (
      albedo.publicKey({
        token: ''
    })
        .then(res => console.log(res.pubkey))
    )
  }
  

export default Demo;
